<template>
  <div class="wx-pub-menus">
    <div class="top-div" v-loading="loading">
      <div class="left">
        <mobile-simulation
          :menus="menus"
          @add-top-menu="doAddTopMenu"
          @add-sub-menu="doAddSubMenu"
          @click-top-menu="doClickTopMenu"
          @click-sub-menu="doClickSubMenu"
        />
      </div>
      <div class="right">
        <menu-editor :menu="currentMenu" @delete-menu="deleteMenu" @save-menu="this.doSaveTemp" />
      </div>
    </div>
    <div class="bottom-div">
      <el-button type="primary" :loading="saving" size="mini" @click="save" v-power="`40-30-10`">保存</el-button>
      <el-button type="warning" :loading="publishing" size="mini" @click="publish" v-power="`40-30-20`" >发布</el-button>
      <el-button type="warning" :loading="downloading" size="mini" @click="download" v-power="`40-30-30`" >下载</el-button>
    </div>
  </div>
</template>

<script>
import commonComputeds from '@/utils/commonComputeds'
export default {
  name: "wx-menu-config",
  data() {
    return {
      menus: [],
      saving: false,
      publishing: false,
      downloading: false,
      loading: false,
      currentMenu: null
    };
  },
  computed: {
    ...commonComputeds
  },
  methods: {
    async download() {
      try {
        this.downloading = true;
        await this.$http.doApi('fw-wx-pub-menu-download');
        this.$message.success('下载成功');
        this.loadWxMenus();
      } catch(e) {
        console.log(e);
      } finally {
        this.downloading = false;
      }
    },
    async publish() {
      try {
        this.publishing = true;
        await this.$http.doApi('fw-wx-pub-menu-publish');
        this.$message.success('发布成功');
      } catch(e) {
        console.log(e);
      } finally {
        this.publishing = false;
      }
    },
    async save() {
      try {
        this.saving = true;

        this.menus.forEach(item => {
          item.key = item.handlerId;
          item.sub_button.forEach(item => item.key = item.handlerId);
        });
        
        await this.$http.doApi('fw-wx-pub-menu-saveFull', {}, this.menus);
        this.$message.success('保存成功');
      } catch (e) {
        console.log(e);
      } finally {
        this.saving = false;
      }
    },
    doSaveTemp(oldMenu, newMenu) {
      for (let i = 0; i < this.menus.length; i++) {
        if (this.isSameMenu(this.menus[i], oldMenu)) {
          this.$set(this.menus, i, newMenu);
          break;
        }
        let leaveFor = false;
        for (let j = 0; j < this.menus[i].sub_button.length; j++) {
          if (this.isSameMenu(this.menus[i].sub_button[j], oldMenu)) {
            this.$set(this.menus[i].sub_button, j, newMenu);
            leaveFor = true;
            break;
          }
        }
        if (leaveFor) {
          break;
        }
      }
      this.currentMenu = newMenu;
    },
    isSameMenu(menu1, menu2) {
      return (menu1.key > 0 && menu1.key === menu2.key) || (menu1.id && menu1.id === menu2.id);
    },
    deleteMenu(menu) {
      let idx = this.menus.indexOf(menu);
      if (idx === -1) {
        for (let i = 0; i < this.menus.length; i++) {
          let subIdx = this.menus[i].sub_button.indexOf(menu);
          if (subIdx >= 0) {
            this.menus[i].sub_button.splice(subIdx, 1);
          }
        }
      } else {
        this.menus.splice(idx, 1);
      }
      this.currentMenu = null;
    },
    doAddTopMenu() {
      let newMenu = {
        name: "菜单名称",
        sub_button: [],
        key: Date.now(),
        showChildren: true
      };
      this.menus.push(newMenu);
      this.currentMenu = newMenu;
    },
    doAddSubMenu(item) {
      let newMenu = {
        name: "菜单名称",
        key: Date.now()
      };
      item.sub_button.push(newMenu);
      this.currentMenu = newMenu;
    },
    doClickTopMenu(topMenu) {
      this.currentMenu = topMenu;
    },
    doClickSubMenu(subMenu) {
      this.currentMenu = subMenu;
    },
    async loadWxMenus() {
      if (!this.currentAppId || this.currentAppId <= 0) {
        return;
      }
      try {
        this.loading = true;
        let data = await this.$http.doApi('fw-wx-pub-menu-getFull');
        let rows = data.rows;
        rows.forEach(item => {
          item.handlerId = parseInt(item.key);
          delete item.key;
          item.sub_button.forEach(subItem => {
            subItem.handlerId = parseInt(subItem.key)
            delete subItem.key;
          });
        })
        this.menus = rows;
      } catch(e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.$eventBus.$on("currentAppChanged", () => {
      this.loadWxMenus();
      this.currentMenu = null;
    });
    this.loadWxMenus();
  },
  components: {
    "mobile-simulation": () => import("./MobileSimulation.vue"),
    'menu-editor': () => import("./MenuEditor.vue")
  },
};
</script>

<style lang="less">
.wx-pub-menus {
  height: 100%;
  display: flex;
  flex-direction: column;
  
  .top-div {
    display: flex;
    flex: 10;
    .left {
      flex: 1;
      display: flex;
      border: @hair-border-ccc;
      justify-content: center;
      align-items: center;
    }
    .right {
      flex: 3;
      border: @hair-border-ccc;
      border-left: 0;
    }
  }

  .bottom-div {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>